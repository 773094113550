import React from 'react'
import classnames from 'classnames'
import Title from '../title/title'
import Banner from './banner/banner'
import Testimonial from './testimonial/testimonial'
import CenteredSectionWrapper from '../centered_section_wrapper/centered_section_wrapper'
import ihm from './ihm.png'
import bbh from './bbh.png'
import andy from './andy.jpg'
import marissa from './marissa.jpg'
import styles from './testimonials_section.module.css'

const TestimonialsSection = () => (
  <div className={styles.root}>
    <CenteredSectionWrapper>
      <Title
        royaltyText='What They Say'
        titleText="We're used by independent freelancers to creative agencies and some of the world's most recognized brands"
      />
    </CenteredSectionWrapper>
    <Banner />
    <CenteredSectionWrapper>
      <div className={styles.columns}>
        <div className={classnames(styles.column, styles.columnLeft)}>
          <Testimonial logo={ihm} authorName='Atzimba Palomares Barriga' authorAffiliation='iHeartMedia'>
            Envato definitely comes in handy and makes my work so much easier when I need to tackle bigger projects that
            involve commercial work because the wide variety of video templates that are available are super easy to
            use.
          </Testimonial>
          <Testimonial
            authorName='Andy Wilkerson (aka Parallelus)'
            authorAffiliation='Envato Author'
            authorImage={andy}
            videoHref='https://www.youtube.com/watch?v=EfTUpvxEbqc&t=139s'
          >
            As soon as I became an author on Envato, I quickly had some success and started to see revenue coming in, it
            was apparent very quickly that I did not need to keep my regular job. So I started my own company...it gave
            me a lot of freedom to do what I want and work for myself. The idea that my success is driven by my own
            ideas, having that control is a really nice side effect of working for yourself.
          </Testimonial>
        </div>
        <div className={classnames(styles.column, styles.columnRight)}>
          <Testimonial logo={bbh} authorName='Dillah Zakbah' authorAffiliation='BBH'>
            Envato Elements is one of those platforms that I log onto on a daily basis sometimes even when I don't need
            to design anything. I get visual inspiration and feel empowered having this library of resources to make our
            ideas come to life.
          </Testimonial>
          <Testimonial
            authorName='Marissa Joyner'
            authorAffiliation='Envato Author'
            authorImage={marissa}
            videoHref='https://www.youtube.com/watch?v=2a-RRfXKeeA&t=3s'
          >
            The benefits of being at Envato are that you get more eyeballs on your work, people get to see what you can
            do. I've seen people use my templates for commercials, corporate videos, video greetings for
            customers...pretty much anything you can think of.
          </Testimonial>
        </div>
      </div>
    </CenteredSectionWrapper>
  </div>
)

export default TestimonialsSection
