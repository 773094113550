import React from 'react'
import { AnchorWithArrow } from '@envato/ui'
import PropTypes from 'prop-types'
import styles from './testimonial.module.css'

const Testimonial = ({ logo, authorImage, authorName, authorAffiliation, videoHref, children }) => (
  <div className={styles.root}>
    {logo ? (
      <div className={styles.logo}>
        <img loading='lazy' className={styles.img} src={logo} alt='Logo' />
      </div>
    ) : null}
    {children}
    <div className={styles.signatureBlock}>
      {authorImage ? <img loading='lazy' className={styles.authorImage} src={authorImage} alt={authorName} /> : null}
      <div className={styles.signature}>
        <p className={styles.name}>{authorName}</p>
        <p className={styles.affiliation}>{authorAffiliation}</p>
      </div>
    </div>
    {videoHref ? (
      <AnchorWithArrow className={styles.watch} href={videoHref}>
        Watch Video
      </AnchorWithArrow>
    ) : null}
  </div>
)

Testimonial.propTypes = {
  logo: PropTypes.string,
  authorImage: PropTypes.string,
  authorName: PropTypes.string.isRequired,
  authorAffiliation: PropTypes.string.isRequired,
  videoHref: PropTypes.string
}

export default Testimonial
