import React from 'react'
import netflix from '../netflix.svg'
import walmart from '../walmart.svg'
import adobe from '../adobe.svg'
import microsoft from '../microsoft.svg'
import ihm from '../ihm.svg'
import google from '../google.svg'
import styles from './banner.module.css'

const Banner = () => (
  <div className={styles.root}>
    <div className={styles.row}>
      <div className={styles.logoContainer}>
        <img loading='lazy' className={styles.logo} src={netflix} alt='Netflix logo' />
      </div>
      <div className={styles.logoContainer}>
        <img loading='lazy' className={styles.logo} src={walmart} alt='Walmart logo' />
      </div>
      <div className={styles.logoContainer}>
        <img loading='lazy' className={styles.logo} src={adobe} alt='Adobe logo' />
      </div>
    </div>
    <div className={styles.row}>
      <div className={styles.logoContainer}>
        <img loading='lazy' className={styles.logo} src={microsoft} alt='Microsoft logo' />
      </div>
      <div className={styles.logoContainer}>
        <img loading='lazy' className={styles.logo} src={ihm} alt='iHeartMedia logo' />
      </div>
      <div className={styles.logoContainer}>
        <img loading='lazy' className={styles.logo} src={google} alt='Google logo' />
      </div>
    </div>
  </div>
)

export default Banner
